import React from "react";

function Promo(props) {
  return (
    <div className="promo">
      <h1 className="promo__header">
        Учебный проект студента факультета Веб-разработки.
      </h1>
    </div>
  );
}

export default Promo;
